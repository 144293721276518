.container {
    align-items: center;
    justify-items: center;
    margin-top: 50px;
}

.inputGroupContainer {
    padding: 15px;
    border: 1px solid rgba(129, 117, 112, 0.226);
    border-radius: 5px;
    padding-bottom: 20px;
}

.form-card {
    background-color: rgb(247, 242, 235);
}